import React from 'react'
import Layout from '../components/layout/Layout'
import CustomShopMain from '../components/main/CustomShopMail'

const CustomShop = () => {
  return (
    <Layout>
        <CustomShopMain/>
    </Layout> 
  )
}

export default CustomShop