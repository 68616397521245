import React from "react";
 
import Layout from "../components/layout/Layout";

const About = () => {
  return (
    <Layout>
 
    </Layout>
  );
};

export default About;
